<template>
    <div class="results-container">
          <span v-if='smsg != ""' class="is-success smessage">{{ this.smsg }}</span>
          <span v-if='emsg != ""' class="is-error emessage">{{ this.emsg }}</span>
    </div>
</template>
<script>
export default {
    props: {
      q: String,
    },
    data() {
      return {
        smsg: "",
        emsg: "",
        data: [],
        loading: false,
        columns: [
          {
              field: 'add',
              label: 'Add',
              width: 60,
          },
          {
              field: 'name',
              label: 'Name',
              width: 100,
              searchable: true,
              sortable: true,
          },
          {
              field: 'email',
              label: 'E-Mail',
              width: 100,
              searchable: true,
          },
          {
              field: 'lock',
              label: 'Lock',
              width: 70,
          },
          {
              field: 'device',
              label: 'Device',
              width: 50,
          }
        ]
      }
    },
    watch: {
      '$props':{
        handler: function (val, oldVal) { 
          console.log('watch', val);
          console.log('watch', oldVal);
          this.message="";
          this.getData();
        },
        deep: true
      }
    },
    created() {
      this.getData();
    },
    methods: {
      getData() {
        requestAnimationFrame(() => {
          this.load();
          this.smsg = "";
          this.emsg = "";
          var api = process.env.VUE_APP_API;
          var query = this.q;
          console.log("API: " + api);
          console.log("Inviting: " + query);
          this.loading = true;

          this.$store.dispatch('auth/invite_user', query).then(
          (response) => {
                  console.log(response);
                  if (response.status == true) {
                      console.log("if loop: ");
                      this.smsg = "Invitation Sent!";
                  } else {
                    console.log("Failed to send invite...");
                    this.emsg = "Failed to send invite...";
                  }
              },
              error => {
                  this.emsg = "Server error..."
                  this.data = [];
                  this.loading = false;
                  console.log(error);
              }
          ); 
        });
      },
      load() {
        let loader = this.$loading.show({
          container: this.fullPage,
          onCancel: this.onCancel,
          active: this.isLoading
        });
        setTimeout(() => {
          loader.hide()
        },1000)
      },
      onCancel() {
        console.log('User cancelled the loader.')
      }
    }
}
</script>
<style lang='scss' scoped>
h1 {
    font-weight: bold;
}
.results-container {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 40px;
}
pre {
  font-family: Consolas, monospace;
  color: #000;
  background: #fff;
  border-radius: 2px;
  padding: 15px;
  line-height: 1.5;
  overflow: auto;
}
.search-results {
  padding-top: 30px;
}
table.table {
    table-layout: fixed;
}
.smessage {
  text-align: center;
  color: #aac734;
  margin-bottom: 30px;
}
.emessage {
  text-align: center;
  color: red;
  margin-bottom: 30px;
}
.mini-button {
  padding: 10px;
  margin-left: 5px;
  height: 15px;
}
</style>